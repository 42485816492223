import React from "react";
import { AccordionCustomWrapper } from "./styled";
import AccordionCustomItem from "./AccordionCustomItem";

const AccordionCustom = ({ items }) => {
	return (
		<AccordionCustomWrapper>
			{items?.map((item, index) => {
				return <AccordionCustomItem key={index} item={item} />;
			})}
		</AccordionCustomWrapper>
	);
};

export default AccordionCustom;
