import styled from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";

export const AccordionCustomWrapper = styled.section`
	margin-bottom: 50px;
`;

export const AccordionItem = styled.div`
	padding: 24px 24px 24px 0;
	border-bottom: 1px solid ${colors.black00};
	@media ${mediaQueryMax.md} {
		padding: 24px 12px;
	}
	.title {
		cursor: pointer;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 0 24px;
		h4 {
			flex: 1;
			margin: 0;
			font-size: 22px;
		}
		.iconWrapper {
			width: 24px;
			height: 24px;
			//overflow: hidden;
			position: relative;
			span.icon {
				display: flex;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				transition: opacity 0.3s ease-in-out;
				span {
					display: flex;
					width: 100%;
					height: 100%;
					svg {
						width: 100%;
						height: auto;
					}
				}
				&.plus {
					opacity: ${(props) => (props.active ? 0 : 1)};
				}
				&.minus {
					opacity: ${(props) => (props.active ? 1 : 0)};
				}
			}
		}
	}
	.text {
		position: relative;
		overflow: hidden;
		.textInner {
			padding-top: 24px;
			padding-right: 48px;
			color: ${colors.gray82};
			@media ${mediaQueryMax.md} {
				padding-right: 0;
			}
			p {
				color: inherit;
			}
		}
	}
`;
