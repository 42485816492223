import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/help.module.scss";
import "../assets/css/helpAccordion.scss";
import PageHeader from "../components/Common/PageHeader";
import { colors } from "../assets/styles/vars";
import {
	PageContent,
	PageContentWrapper,
	PageTwoColWrapper,
} from "../assets/styles/pageCommon";
import AccordionCustom from "../components/Common/AccordionCustom";
import { CustomButton } from "../assets/styles/globalCommon";
import { ayudaData } from "../data/global-data";
import { SliderButton } from "@typeform/embed-react";

const AyudaPage = () => (
	<Layout>
		<Seo title="Ayuda" />
		<section className={styles.help}>
			<PageHeader title="Ayuda" />

			<PageContentWrapper bgColor={colors.grayF2}>
				<PageContent width="1050px">
					<PageTwoColWrapper>
						<div className="pageCol leftCol">
							<h3>¿Necesitas ayuda?</h3>
							<p>
								Aquí respondemos algunas preguntas que nos llegan
								frecuentemente, si lo que buscas no está aquí, por favor
								escríbenos y trataremos de responder tu consulta lo
								antes posible.
							</p>
						</div>

						<div className="pageCol fullCol">
							<AccordionCustom items={ayudaData} />
						</div>

						<div className="pageCol leftCol">
							<p>Si tienes más duda envíanos un consulta.</p>
							<br />
							<SliderButton id="kr8xczVw">
								<CustomButton className="ghost withUnderline">
									<p>Enviar Consulta</p>
								</CustomButton>
							</SliderButton>
						</div>
					</PageTwoColWrapper>
				</PageContent>
			</PageContentWrapper>
		</section>
	</Layout>
);

export default AyudaPage;
