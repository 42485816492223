import React, { useEffect, useRef, useState } from "react";
import { AccordionItem } from "./styled";
import { ReactSVG } from "react-svg";
import { Transition } from "react-transition-group";

const AccordionCustomItem = ({ item }) => {
	const [active, setActive] = useState(false);
	const [height, setHeight] = useState(0);
	const [transitionStyles, setTransitionStyles] = useState({});
	const itemTextRef = useRef();

	const duration = 500;

	const defaultStyle = {
		transition: `all ${duration}ms ease-in-out`,
		opacity: 0,
		visibility: "hidden",
		height: "auto",
	};

	useEffect(() => {
		// console.log(
		// 	"ref",
		// 	itemTextRef.current,
		// 	itemTextRef.current?.clientHeight,
		// );
		const itemHeight = itemTextRef.current?.clientHeight;
		setHeight(itemHeight);

		setTransitionStyles({
			entering: {},
			entered: {
				opacity: 1,
				visibility: "visible",
				height: "auto",
			},
			exiting: {},
			exited: { opacity: 0, visibility: "hidden", height: 0 },
		});
	}, []);

	useEffect(() => {
		// console.log("height", height);
		if (height !== 0) {
			setTransitionStyles((state) => ({
				...state,
				entering: {
					opacity: 1,
					visibility: "visible",
					height: `${height}px`,
				},
				exiting: {
					opacity: 0,
					visibility: "hidden",
					height: `${height}px`,
				},
			}));
		}
	}, [height]);

	// RESIZE
	const resizeListener = () => {
		const itemHeight = itemTextRef.current?.clientHeight;
		// console.log("RESIZE itemHeight", itemHeight);
		setHeight(itemTextRef.current?.clientHeight);
	};
	useEffect(() => {
		// resizeListener();
		window.addEventListener("resize", resizeListener);

		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, []);

	return (
		<AccordionItem active={active}>
			<div className="title" onClick={() => setActive(!active)}>
				<h4>{item.title}</h4>
				<div className="iconWrapper">
					<ReactSVG
						src="/img/icons/icon-plus.svg"
						wrapper="span"
						className="icon plus"
					/>
					<ReactSVG
						src="/img/icons/icon-minus.svg"
						wrapper="span"
						className="icon minus"
					/>
				</div>
			</div>

			<Transition in={active} timeout={300}>
				{(state) => {
					return (
						<div
							ref={itemTextRef}
							style={{
								...defaultStyle,
								...transitionStyles[state],
							}}
							className="text"
						>
							<div className="textInner">
								<p dangerouslySetInnerHTML={{ __html: item.text }} />
							</div>
						</div>
					);
				}}
			</Transition>
		</AccordionItem>
	);
};

export default AccordionCustomItem;
